import "amazon-connect-streams";
import React, { memo, useRef, useEffect, useContext } from "react";
import { genLogger } from "../lib";
import { DataProvider } from "../contexts/DataTransfer";

const name = "ConnectCCP";
const { log, error } = genLogger(name);

const ConnectCCP = () => {
  const { popUp ,
  agentSubscription,
  contactSubscription,
  authorizationFailed,
  authorizationSuccess,
  logoutEventSubscription
  } = useContext(DataProvider);
  const ref = useRef();
  useEffect(() => {
    try {
      if (typeof window === "undefined") throw new Error("window missing");
      if (typeof window.connect === "undefined")
        throw new Error("global connect missing");
      window.connect.core.initCCP(ref.current, {
        ccpUrl: "https://realtytek.my.connect.aws/ccp-v2#",
        loginUrl: "https://realtytek.my.connect.aws/login",
        region: "ca-central-1",
        loginPopup: { popUp },
        loginPopupAutoClose: true,
        ccpLoadTimeout: 500 ,
        pageOptions: { //optional
          enableAudioDeviceSettings: true, //optional, defaults to 'false'
          enablePhoneTypeSettings: true //optional, defaults to 'true' 
        },
        softphone: { allowFramedSoftphone: true },
      });

      window.connect.agent(agentSubscription);
      window.connect.contact(contactSubscription);
      window.connect.core.onAuthFail(authorizationFailed);
      window.connect.core.onAuthorizeSuccess(authorizationSuccess);
      window.connect.core.getEventBus(logoutEventSubscription);

      log("init end");
    } catch (e) {
      error(e);
    }
  }, []);

  log("render");
  return (
    <div
      ref={ref}
      id="containerDiv"
      style={{
        width: "100%",
        height: "100%",
        minHeight: 480,
        minWidth: 100,
      }}
    />
  );
};

export default memo(ConnectCCP);
