import "amazon-connect-streams";
import React, { useEffect, useRef, useContext } from "react";
import phone from "../assets/svg/phone.svg";
import DisplayClients from "./DisplayClients";
import IdCallbtn from "./IdCallbtn";
import LoginLogoutBtn from "./LoginLogoutBtn";
import SearchInput from "./SearchInput";
import { DataProvider } from "../contexts/DataTransfer";

const CallerId = () => {
  const {
    clients,
    search,
    agentId,
    incommingConnectQName,
    currentCallerId,
    userSearch,
    handleSubmit,
    loginLogout,
    getClientData,
    handleLoginLogout,
  } = useContext(DataProvider);
  const ref = useRef();

  useEffect(() => {
    getClientData();
  }, []);

  return (
    <React.Fragment>
      <div>
        <div>
          <div ref={ref}> </div>
          <div className="d-flex py_10 px-4 header-box justify-content-between">
            <p className="fs-5 my-auto ">Agent ID : {agentId}</p>
            <p className="fs-5 my-auto ">
              <LoginLogoutBtn
                handleLoginLogout={handleLoginLogout}
                loginLogout={loginLogout}
              />{" "}
            </p>
          </div>
          <div className="d-flex justify-content-between mt-5">
            <div className="call-box-shadow me-1 d-flex justify-content-center ">
              <p className="my-auto text-center">
                Queue:{" "}
                <span className="text_blue">{incommingConnectQName}</span>
              </p>
            </div>
            <div className="call-box-shadow ms-1 d-flex justify-content-center">
              <p className=" my-auto text-center">
                Caller ID: <span className="text_red">{currentCallerId}</span>
              </p>
            </div>
          </div>
          <div className="d-flex flex-sm-row flex-column my-3 justify-content-center ">
            {/* <DisplayCountryCode onChange={phoneNumbertoCall} /> */}
            {/* phoneNumbertoCall={phoneNumbertoCall} */}
            <div className="d-flex mb-3 mb-sm-0">
              <SearchInput userSearch={userSearch} />{" "}
              <DisplayClients clients={clients} search={search} />{" "}
            </div>{" "}
            <IdCallbtn handleSubmit={handleSubmit} phone={phone} />{" "}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CallerId;
