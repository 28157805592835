import React from "react";

const DisplayClients = ({ clients, search }) => {
  const filteredClients = clients.filter((client) =>
    client.ClientName.toLowerCase().includes(search.toLowerCase())
  );
  return (
    <div>
      {filteredClients.length ? (
        <select id="client_dropdown" className="form-select ms-1">
          {filteredClients.map((client) => (
            <option key={client.ClientName}>{client.Client}</option>
          ))}
        </select>
      ) : (
        <p className="no_result_text mt-2 mb-0">No Result Found</p>
      )}
    </div>
  );
};

export default DisplayClients;
