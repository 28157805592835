// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useMemo } from "react";
import AppLayoutBase from "aws-northstar/layouts/AppLayout";
import HeaderBase from "aws-northstar/components/Header";
import logo from "../assets/svg/realtytek_logo.webp";

const AppLayout = ({ children }) => {
  const Header = useMemo(
    () => (
      <HeaderBase
        // title="Anvime"
        logoPath={logo}
        width={300}
      />
    ),
    []
  );
  return <AppLayoutBase header={Header}>{children}</AppLayoutBase>;
};

export default AppLayout;
