import React from "react";

const IdCallbtn = ({ handleSubmit, phone }) => {
  return (
    <div>
      {" "}
      <div className=" d-flex justify-content-center ">
        <button
          className="call-btn py-2 px-2 mx-3"
          id="upd-btn"
          onClick={handleSubmit}
        >
          <span className="me-1">
            <img width={20} alt="" src={phone} />
          </span>
          Set Caller ID{" "}
        </button>{" "}
      </div>
    </div>
  );
};

export default IdCallbtn;
