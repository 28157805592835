import React from "react";
import { Link } from "react-router-dom";

const LoginPage = () => {
  return (
    <div className="container">
      <div className="d-flex py_10 px-4 header-box justify-content-between">
        <p className="text-center fs-5 my-auto ">RealtyTek CCP</p>
      </div>
      <div className="call-box-shadow mt-4 min_h_box my-auto d-flex align-items-center justify-content-center ">
        <div className="d-flex align-item-center my-auto jusitfy-content-between">
          <Link
            to="/dashboard"
            className="d-flex justify-content-center w-100 text-decoration-none mb-4 "
          >
            <button className="btn login_btn mx-auto mt-4">Log In</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
