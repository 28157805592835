import React from "react";
import { createContext, useState, useEffect } from "react";
import { genLogger } from "../lib";
import Swal from "sweetalert2";
export const DataProvider = createContext();
export default function DataTransfer({ children }) {
  const name = "ConnectCCP";
  const { error } = genLogger(name);
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState("");
  const [currentCallerId, setCurrentCallerId] = useState("Pls Login");
  const [loginLogout, setloginLogout] = useState("Login");
  const [agentId, setAgentId] = useState("undefined");
  const [popUp, setPopUp] = useState(true);
  const [incommingConnectQName, setIncommingConnectQName] =
    useState("Waiting for Call");
  const [endpoint, setEndpoint] = useState({
    countrycode: "+1",
    number: "8005550100",
  });
  useEffect(() => {
    try {
      window.connect.agent(agentSubscription);
      window.connect.contact(contactSubscription);
      window.connect.core.onAuthFail(authorizationFailed);
      window.connect.core.onAuthorizeSuccess(authorizationSuccess);
      window.connect.core.getEventBus(logoutEventSubscription);

    } catch (e) {
      error(e);
      console.log(error(e));
    }
  }, []);

  const logoutEventSubscription = (eventBus) => {
    alert("logout");
    eventBus.subscribe(window.connect.EventType.TERMINATED, () => {
      console.log("Logged out");
      document.location.reload();
      // alert(window.connect.core.initCCP.prototype.loginPopup);
      // Do stuff...
    });
  };

  const authorizationFailed = () => {
    setPopUp(false);
    console.log("Not Logged in");
    setAgentId("Pls Log in");
    setloginLogout("Login");
  };

  const authorizationSuccess = () => {
    console.log("Authorised");
    setloginLogout("Logout");
  };

  const contactSubscription = (contact) => {
    console.log("subscribing to contacts..");
    setIncommingConnectQName(contact.getQueue().name);
    contact.onIncoming(() => {
      console.log("Incoming call:");
      setIncommingConnectQName(contact.getQueue().name);
    });
    contact.onConnected(() => {
      console.log("Incoming call connected:");
      setIncommingConnectQName(contact.getQueue().name);
    });
    contact.onEnded(() => {
      console.log("Incoming call over:");
      setIncommingConnectQName("waiting for call");
    });
  };
  const agentSubscription = (agent) => {
    console.log(
      "subscribing to agent.. AgentID is " + agent.getConfiguration().username
    );
    // agent.onWebSocketConnectionGained(()=> {
    if (agent.getConfiguration().username !== "undefined") {
      setAgentId(agent.getConfiguration().username);
      getCurrentCallerId(agent.getConfiguration().username);
      setloginLogout("Logout");
    }
    // });
  };

  async function getCurrentCallerId(agentname) {
    console.log("calling getCurrentCallerId for " + agentname);
    const response = await fetch(
      process.env.REACT_APP_CALLERID_API + "/agents/" + agentname,
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
      }
    );
    const data = await response.json();
    console.log(data.client);
    // setCurrentCallerId("I am in");
    setCurrentCallerId(data.client);
    //    return data.client;
  }

  function agentCallHandler(agent) {
    console.log("calling number" + endpoint.countrycode + endpoint.number);
    try {
      agent.connect(
        window.connect.Endpoint.byPhoneNumber(
          endpoint.countrycode + endpoint.number
        ),
        {
          success: function () {
            console.log("outbound call connected");
          },
          failure: function (err) {
            console.log("outbound call connection failed");
            Swal.fire({
              icon: "error",
              text: "Error Making a Call Try Using the Dialer",
            });
            console.log(err);
          },
        }
      );
    } catch (e) {
      console.log(e);
    }
  }
  function displayCallerId(event) {
    //
    console.log(currentCallerId.client);
    return (
      <label id="callerid" className="callerid-text p-1 fs-6">
        {currentCallerId.client}
      </label>
    );
  }
  function userSearch(event) {
    setSearch(event.target.value);
  }
  function validatePhoneNumber(input_str) {
    var re = /^\d{10}$/;
    console.log(input_str, re.test(input_str));
    return re.test(input_str);
  }
  const phoneNumbertoCall = (event) => {
    console.log(document.getElementById("countrycodes").value);
    console.log(document.getElementById("callphoneNumber").value);
    setEndpoint({
      countrycode: document.getElementById("countrycodes").value,
      number: document.getElementById("callphoneNumber").value,
    });
  };

  const handleLoginLogout = (event) => {
    if (loginLogout === "Login") {
      doLogin();
      window.location.href = "/dashboard";
    } else {
      doLogout();
      window.location.href = "/";
    }
  };

  const doLogin = () => {
//     alert("login" + popUp);
    setPopUp(true);
    window.connect.core
      .getPopupManager()

  };

  // function onRemoved(cookie) {
  //   console.log(`Removed: ${cookie}`);
  // }

  // function onError(error) {
  //   console.log(`Error removing cookie: ${error}`);
  // }

  // function removeCookie(tabs) {
  //   let removing = browser.cookies.remove({
  //     url: tabs[0].url,
  //     name: "lily-auth-*"
  //   });
  //   removing.then(onRemoved, onError);
  // }

  // let getActive = browser.tabs.query({active: true, currentWindow: true});
  // getActive.then(removeCookie);

  const doLogout = () => {
    setPopUp(false);
    // window.connect.core.terminate();
    // alert("logging out" + popUp );
    // fetch("https://realtytek.my.connect.aws/logout/", { credentials: 'include', mode: 'no-cors'});
    fetch("https://realtytek.my.connect.aws/connect/logout", {
      credentials: "include",
      mode: "no-cors",
    }).then(() => {
      const eventBus = window.connect.core.getEventBus();
      alert(eventBus);
      eventBus.trigger(window.connect.EventType.TERMINATE);
      // clear out last opened timestamp for SAML authentication when there is ACK_TIMEOUT
      // alert(window.params.loginUrl);
      // if (window.params.loginUrl) {
      window.localStorage.removeItem(
        "connectPopupManager::connect::loginPopup"
      );

      // window.connect.core.getPopupManager().clear(window.connect.MasterTopics.LOGIN_POPUP);
      // }
    });
    // window.close();
  };

  const handleSubmit = (event) => {
    if (document.getElementById("client_dropdown")) {
      if (
        event.target.id === "call-btn" &&
        (document.getElementById("callphoneNumber") === "" ||
          !validatePhoneNumber(
            document.getElementById("callphoneNumber").value
          ))
      ) {
        Swal.fire({
          icon: "error",
          text:
            "Please enter a valid phone number with exact 10 digits to call!" +
            document.getElementById("callphoneNumber").value,
        });
      } else {
        console.log("Submit Current AgentId " + agentId);
        const body = {
          agent: agentId,
          client: document.getElementById("client_dropdown").value,
        };

        if (agentId === "undefined") {
          Swal.fire({
            icon: "error",
            text: "Please Login to Connect!",
          });
        } else {
          console.log(body);
          setCurrentCallerId("Updating..");
          updateCallerIdForAgent(
            agentId,
            document.getElementById("client_dropdown").value
          );

          if (event.target.id === "call-btn") {
            window.connect.agent(agentCallHandler);
          }
          return "success";
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "Please search or select a valid Client !",
      });
    }
  };

  async function updateCallerIdForAgent(agentsid, clientname) {
    console.log("calling setCurreupdateCallerIdForAgentntCallerId ");
    const response = await fetch(
      process.env.REACT_APP_CALLERID_API + "/agents/",
      {
        method: "POST",
        headers: {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
        body: JSON.stringify({
          agent: agentsid,
          client: clientname,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => console.log(JSON.stringify(response)));

    console.log(JSON.stringify(response));
    getCurrentCallerId(agentsid);
  }
  async function getClientData() {
    // alert(process.env.REACT_APP_X_API_KEY);
    // alert(process.env.REACT_APP_CALLERID_API);
    const response = await fetch(
      process.env.REACT_APP_CALLERID_API + "/clients/",
      {
        method: "GET",
        headers: {
          "content-type": "text/plain",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
      }
    );
    const data = await response.json();
    setClients(data.Clients);
    console.log(data.Clients);
  }

  const value = {
    clients,
    setClients,
    search,
    setSearch,
    currentCallerId,
    setCurrentCallerId,
    agentId,
    setAgentId,
    incommingConnectQName,
    setIncommingConnectQName,
    endpoint,
    setEndpoint,
    contactSubscription,
    agentSubscription,
    getCurrentCallerId,
    agentCallHandler,
    displayCallerId,
    userSearch,
    validatePhoneNumber,
    phoneNumbertoCall,
    handleSubmit,
    getClientData,
    handleLoginLogout,
    loginLogout,
    setPopUp,
    popUp,
  };

  return (
    <DataProvider.Provider value={value}>{children}</DataProvider.Provider>
  );
}
