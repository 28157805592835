import React from "react";

const LoginLogoutBtn = ({ handleLoginLogout, loginLogout }) => {
  if (loginLogout === "Logout") {
    return (
      <button
        className="call-btn py-2 px-2 mx-3"
        id="login-btn"
        onClick={handleLoginLogout}
      >
        {loginLogout}{" "}
      </button>
    );
  }
  return <span />;
};

export default LoginLogoutBtn;
