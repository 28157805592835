import React from "react";

const SearchInput = ({ phoneNumbertoCall, userSearch }) => {
  return (
    <div className="d-flex ">
      {/* {" "}
      <input
        type="number"
        className="form-control number-input mx-2"
        id="callphoneNumber"
        placeholder="8005550100"
        onBlur={phoneNumbertoCall}
      /> */}{" "}
      <input
        type="text"
        className="form-control search-result me-1"
        placeholder="Search String"
        onChange={userSearch}
      />
    </div>
  );
};

export default SearchInput;
