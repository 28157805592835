import React, { useCallback } from "react";
import ConnectCCP from "./ConnectCCP";
import CallerId from "./CallerId";

const Dashboard = () => {
  const CCP = useCallback(() => <ConnectCCP />, []);

  return (

    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-8">
          {" "}
          <CallerId />
        </div>
        <div className="col-sm-4" style={{ height: "85vh" }}>
          {" "}
          <CCP />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
